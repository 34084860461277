/* --- MOBILE STYLES --- */

.about {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: var(--spacing);
}

.aboutContainer {
  border-bottom: 1px solid var(--grey);
}

.personalImage {
  display: flex;
  justify-content: center;
  margin-bottom: var(--spacing);
}

.personalImage img {
  height: 250px;
  z-index: 50;
  border-radius: 250px;
  border: 1px solid var(--grey);
}

.contentContainer {
  padding: 10px;
}

.contentContainer h4 {
  color: var(--hl-color);
  font-size: 18px;
  font-weight: 400;
  margin-bottom: 15px;
}

.contentContainer h5 {
  color: var(--text-color);
  font-size: 20px;
  margin-bottom: 15px;
}

.contentDescription {
  color: var(--secondary-text-color);
}

.infoContainer {
  border-top: 1px solid var(--grey);
  margin-top: var(--sm-spacing);
  padding-top: var(--sm-spacing);
}

.info {
  padding-left: 0;
}

.infoContainer span {
  color: var(--text-color);
}

.infoContainer p {
  color: var(--secondary-text-color);
  display: inline-block;
  margin-left: 10px;
  margin-bottom: 10px;
}

.infoContainer a {
  color: var(--hl-color);
}

.infoContainer a:hover {
  color: #03b979;
}

.buttonContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-right: var(--sm-spacing);
}

.downloadCV {
  margin-top: var(--sm-spacing);
  margin-bottom: var(--sm-spacing);
}

.about .socialIcons .icon {
  display: none;
}

@media (min-width: 750px) {
  .about .socialIcons .icon {
    display: inline;
  }

  .about .socialIcons::before {
    background-color: var(--grey);
    content: "";
    height: 2px;
    width: var(--sm-spacing);
    display: inline-block;
    margin-bottom: 5px;
    margin-right: 10px;
  }
}

@media (min-width: 992px) {
  .personalImage img {
    height: 330px;
    z-index: 50;
    border-radius: 0;
    padding: 6px;
  }

  .personalImage {
    height: 330px;

    display: flex;
    align-items: center;
    justify-content: center;
  }
}
